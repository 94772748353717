@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media (min-width: 576px) {
  .modal-dialog:has(.modal-right75) {
    max-width: 32rem !important;
    margin: 1.75rem auto;
  }
}
@font-face {
  font-family: SegoeUI;
  src: url(../Segoe-UI-Font/SEGOEUI.woff);
}
* {
  font-family: "SegoeUI" !important;
}
.modal-right * {
  font-family: "SegoeUI" !important;
}
form label {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
  display: inline-block;
  padding: 5px 0px;
  overflow-wrap: break-word;
}
/* .modal-right.h-100.modal-body{
  box-sizing: border-box;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: rgb(50, 49, 48);
  box-sizing: border-box;
  box-shadow: none;
  margin: 0px;
 
  padding: 5px 0px;
  overflow-wrap: break-word;
} */
:root {
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: ;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #ffffff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #e9e9ef;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.4rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #84091d;
  --bs-link-hover-color: #6a0717;
  --bs-code-color: #e83e8c;
  --bs-highlight-bg: #fcf8e3;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body * {
  outline: none !important;
}
button,
a {
  outline: none !important;
}
a {
  text-decoration: none !important;
}
#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 3.3rem;
  font-size: 0.85rem;
  font-weight: bolder;
  color: #545a6d;
}
.mm-active > a {
  color: #84091d !important;
}
#sidebar-menu ul li a {
  display: block;
  padding: 0.62rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-weight: 700;
  font-size: 0.9rem;
  transition: all 0.4s;
  font-weight: 500;
}

#sidebar-menu .metismenu.list-unstyled.mm-show.mm-active .mm-active a.active {
  border-left: 5px solid #84091d;
}
.dashboard-height {
  height: 87vh !important;
  overflow: auto;
}

.metismenu {
  margin: 0;
  position: absolute;
  z-index: 999;
  max-height: 89vh;
  overflow: auto;
}
.employeeGraph {
  box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.1);
  margin-top: 49px;
  /* width: 710px; */
  border-radius: 16px;
}

.metismenu li {
  display: block;
  width: 100%;
}
.metismenu .mm-collapse {
  display: none;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapse.mm-show {
  display: block;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #fbfaff;
  bottom: 0;
  margin-top: 0;

  top: 60px;
  border-right: 1px solid #e9e9ef;
}

.main-content {
  margin-left: 250px;
  overflow: hidden;
  max-height: 100vh;
}
.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 60px;
}
#sidebar-menu ul li a {
  display: block;
  padding: 0.62rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 0.9rem;
  transition: all 0.4s;
  font-weight: 500;
}
.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #fbfaff;
  bottom: 0;
  margin-top: 0;

  border-right: 1px solid #e9e9ef;
}
#sidebar-menu {
  padding: 10px 0 30px 0;
  height: calc(100vh - 5rem);
  overflow: auto;
}
#sidebar-menu .mm-active > .has-arrow:after {
  transform: rotate(90deg);
}
#sidebar-menu .has-arrow:after {
  content: "\f0142";
  font-family: "Material Design Icons";
  display: block;
  float: right;
  transition: transform 0.2s;
  font-size: 1.1rem;
  margin-right: -5px;
  margin-top: -2px;
}

#side-menu {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #84091d #f5f5f5; /* Firefox */
}

#side-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#side-menu::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#side-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #84091d;
}

* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #84091d #f5f5f5; /* Firefox */
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #84091d;
}

.cardDesginCustomAreaVoiation {
  overflow: hidden auto;
  max-height: calc(98vh - 18rem);
  min-height: calc(98vh - 17rem);
  /* min-height: calc(98vh - 16.5rem); */
}

#sidebar-menu ul li a {
  display: block;
  padding: 0.62rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 0.9rem;
  transition: all 0.4s;
  font-weight: 500;
}
#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #545a6d;
  transition: all 0.4s;
}
#sidebar-menu ul li a svg {
  height: 16px;
  width: 16px;
  color: #545a6d;
  fill: rgba(84, 90, 109, 0.2);
  margin-right: 10px;
  margin-top: -3px;
}
#sidebar-menu ul li a:hover {
  color: #84091d;
}
#sidebar-menu ul li a:hover i {
  color: #84091d;
}
#sidebar-menu ul li a:hover svg {
  color: #84091d;
  fill: rgba(132, 9, 29, 0.2);
}
#sidebar-menu ul li .badge {
  margin-top: 4px;
}
#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #84091d;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}
#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13px;
}

.menu-title {
  padding: 12px 20px !important;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  color: #545a6d;
  font-weight: 500;
}

.mm-active > a {
  color: #84091d !important;
}
.mm-active > a i {
  color: #84091d !important;
}
.mm-active > a svg {
  color: #84091d !important;
  fill: rgba(132, 9, 29, 0.2) !important;
}
.mm-active .active {
  color: #84091d !important;
}
.mm-active .active i {
  color: #84091d !important;
}
.mm-active .active svg {
  color: #84091d !important;
  fill: rgba(132, 9, 29, 0.2) !important;
}
.mm-active > i {
  color: #84091d !important;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }
  .main-content {
    margin-left: 0 !important;
  }
  body.sidebar-enable .vertical-menu {
    display: block;
  }
}

.sm .main-content {
  margin-left: 70px;
}
.sm .navbar-brand-box {
  width: 70px !important;
}
.sm .logo span.logo-lg {
  display: none;
}
.sm .logo span.logo-sm {
  display: block;
}
.sm .vertical-menu {
  width: 70px !important;
  /* z-index: 5; */
}
.sm .vertical-menu .simplebar-mask,
.sm .vertical-menu .simplebar-content-wrapper {
  overflow: visible !important;
}
.sm .vertical-menu .simplebar-scrollbar {
  display: none !important;
}
.sm .vertical-menu .simplebar-offset {
  bottom: 0 !important;
}
.sm .vertical-menu #sidebar-menu .menu-title,
.sm .vertical-menu #sidebar-menu .badge,
.sm .vertical-menu #sidebar-menu .sidebar-alert {
  display: none !important;
}
.sm .vertical-menu #sidebar-menu .nav.collapse {
  height: inherit !important;
}
.sm .vertical-menu #sidebar-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.sm .vertical-menu #sidebar-menu > ul > li > a {
  padding: 15px 20px;
  transition: none;
}
.sm .vertical-menu #sidebar-menu > ul > li > a:hover,
.sm .vertical-menu #sidebar-menu > ul > li > a:active,
.sm .vertical-menu #sidebar-menu > ul > li > a:focus {
  color: #84091d !important;
}
.sm .vertical-menu #sidebar-menu > ul > li > a i {
  font-size: 1rem;
  margin-left: 4px;
}
.sm .vertical-menu #sidebar-menu > ul > li > a svg {
  height: 18px;
  width: 18px;
  margin-left: 6px;
}
.sm .table-responsive {
  max-width: calc(100vw - 9.3rem);
}
.sm .vertical-menu #sidebar-menu > ul > li > a span {
  display: none;
  padding-left: 25px;
}
.sm .vertical-menu #sidebar-menu > ul > li > a.has-arrow:after {
  display: none;
}
.sm .vertical-menu #sidebar-menu > ul::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for Chrome, Safari, and Opera */
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > a {
  position: relative;

  width: calc(190px + 70px);
  color: #810c1c;
  background-color: #ebe6ff;
  transition: none;
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > a i {
  color: #84091d;
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > a svg {
  color: #84091d;
  fill: rgba(132, 9, 29, 0.2);
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > a span {
  display: inline;
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > ul {
  display: block;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > ul a {
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
  color: #545a6d;
}
.sm .vertical-menu #sidebar-menu > ul > li:hover > ul a:hover {
  color: #84091d;
}
.sm .vertical-menu #sidebar-menu > ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: white;
}
.sm .vertical-menu #sidebar-menu > ul ul li:hover > ul {
  display: block;
  left: 190px;
  height: auto !important;
  margin-top: -36px;
  position: absolute;
  width: 190px;
  padding: 5px 0;
}
.sm .vertical-menu #sidebar-menu > ul ul li > a span.pull-right {
  position: absolute;
  right: 20px;
  top: 12px;
  transform: rotate(270deg);
}
.sm .vertical-menu #sidebar-menu > ul ul li.active a {
  color: gray;
}
.sm #sidebar-menu .mm-active > .has-arrow:after {
  transform: rotate(0deg);
}

body[data-sidebar="dark"] .navbar-brand-box {
  background: #2c302e;
  box-shadow: 0px 3px 1px #2c302e;
  border-color: #2c302e;
}
body[data-sidebar="dark"] .navbar-brand-box .logo {
  color: white !important;
}
body[data-sidebar="dark"] .logo-dark {
  display: none;
}
body[data-sidebar="dark"] .logo-light {
  display: block;
}
body[data-sidebar="dark"] .vertical-menu {
  background: #2c302e;
  border-color: #2c302e;
}
@media (min-width: 992px) {
  body[data-sidebar="dark"] #vertical-menu-btn {
    color: #e9ecef;
  }
}
body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #99a4b1;
}
body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #858d98;
}
body[data-sidebar="dark"] #sidebar-menu ul li a svg {
  color: #858d98;
  fill: rgba(133, 141, 152, 0.2);
}
body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
  color: #ffffff;
}
body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #ffffff;
}
body[data-sidebar="dark"] #sidebar-menu ul li a:hover svg {
  color: #ffffff;
  fill: rgba(255, 255, 255, 0.2);
}
body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #858d98;
}
body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #ffffff;
}
body[data-sidebar="dark"].sm[data-topbar="dark"] #vertical-menu-btn {
  color: #e9ecef;
}
body[data-sidebar="dark"].sm #vertical-menu-btn {
  color: #555b6d;
}
body[data-sidebar="dark"].sm .vertical-menu #sidebar-menu > ul > li:hover > a {
  background: #313533;
  color: #ffffff;
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  i {
  color: #ffffff;
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  svg {
  color: #ffffff;
  fill: rgba(255, 255, 255, 0.2);
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a {
  color: #858d98;
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > ul
  a:hover {
  color: #ffffff;
}
body[data-sidebar="dark"].sm .vertical-menu #sidebar-menu > ul ul {
  background-color: #2c302e;
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active {
  color: #ffffff !important;
}
body[data-sidebar="dark"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active
  i {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active > a {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active > a i {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active > a svg {
  color: #ffffff !important;
  fill: rgba(255, 255, 255, 0.2) !important;
}
body[data-sidebar="dark"] .mm-active > i {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active .active {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active .active i {
  color: #ffffff !important;
}
body[data-sidebar="dark"] .mm-active .active svg {
  color: #ffffff !important;
  fill: rgba(255, 255, 255, 0.2) !important;
}
body[data-sidebar="dark"] .menu-title {
  color: #858d98;
}
body[data-sidebar="dark"][data-sidebar-size="md"]
  #sidebar-menu
  ul
  li.menu-title {
  background-color: #313533;
}

body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size="md"] .navbar-brand-box {
  width: 120px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size="md"] .navbar-brand-box {
    width: auto;
  }
}
body[data-sidebar-size="md"] .vertical-menu {
  width: 120px;
  text-align: center;
}
body[data-sidebar-size="md"] .vertical-menu .has-arrow:after,
body[data-sidebar-size="md"] .vertical-menu .badge,
body[data-sidebar-size="md"] .vertical-menu .sidebar-alert {
  display: none !important;
}
body[data-sidebar-size="md"] .main-content {
  margin-left: 120px;
}
body[data-sidebar-size="md"] .footer {
  left: 120px;
}
@media (max-width: 991.98px) {
  body[data-sidebar-size="md"] .footer {
    left: 0;
  }
}
body[data-sidebar-size="md"] #sidebar-menu ul li a svg {
  display: block;
  margin: 0 auto 4px;
}
body[data-sidebar-size="md"] #sidebar-menu ul li ul.sub-menu li a {
  padding-left: 1.5rem;
}
body[data-sidebar-size="md"]
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li
  ul.sub-menu
  li
  a {
  padding-left: 1.5rem;
}
body[data-sidebar-size="md"].sm .main-content {
  margin-left: 70px;
}
body[data-sidebar-size="md"].sm .vertical-menu #sidebar-menu {
  text-align: left;
}
body[data-sidebar-size="md"].sm .vertical-menu #sidebar-menu > ul > li > a svg {
  display: inline-block;
}
body[data-sidebar-size="md"].sm .footer {
  left: 70px;
}

body[data-sidebar="brand"] .vertical-menu {
  background-color: #84091d;
}
body[data-sidebar="brand"] .navbar-brand-box {
  background-color: #84091d;
  box-shadow: 0px 1px 0px #84091d;
}
body[data-sidebar="brand"] .navbar-brand-box .logo-dark {
  display: none;
}
body[data-sidebar="brand"] .navbar-brand-box .logo-light {
  display: block;
}
body[data-sidebar="brand"] .navbar-brand-box .logo {
  color: white !important;
}
body[data-sidebar="brand"] .mm-active {
  color: white !important;
}
body[data-sidebar="brand"] .mm-active > a {
  color: white !important;
}
body[data-sidebar="brand"] .mm-active > a i {
  color: white !important;
}
body[data-sidebar="brand"] .mm-active > a svg {
  color: white !important;
  fill: rgba(255, 255, 255, 0.1) !important;
}
body[data-sidebar="brand"] .mm-active .active {
  color: white !important;
}
body[data-sidebar="brand"] .mm-active .active svg {
  color: white !important;
  fill: rgba(255, 255, 255, 0.1) !important;
}
@media (min-width: 992px) {
  body[data-sidebar="brand"] #vertical-menu-btn {
    color: #e9ecef;
  }
}
body[data-sidebar="brand"] #sidebar-menu ul li.menu-title {
  color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar="brand"] #sidebar-menu ul li a {
  color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar="brand"] #sidebar-menu ul li a i {
  color: rgba(255, 255, 255, 0.6);
}
body[data-sidebar="brand"] #sidebar-menu ul li a svg {
  color: rgba(255, 255, 255, 0.6);
  fill: rgba(255, 255, 255, 0.075);
}
body[data-sidebar="brand"] #sidebar-menu ul li a.waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.1);
}
body[data-sidebar="brand"] #sidebar-menu ul li a:hover {
  color: white;
}
body[data-sidebar="brand"] #sidebar-menu ul li a:hover i {
  color: white;
}
body[data-sidebar="brand"] #sidebar-menu ul li ul.sub-menu li a {
  color: rgba(255, 255, 255, 0.5);
}
body[data-sidebar="brand"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: white;
}
body[data-sidebar="brand"] .sidebar-alert {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}
body[data-sidebar="brand"] .sidebar-alert .alertcard-title {
  color: white;
}
body[data-sidebar="brand"].sm[data-topbar="dark"] #vertical-menu-btn {
  color: #e9ecef;
}
body[data-sidebar="brand"].sm #vertical-menu-btn {
  color: #555b6d;
}
body[data-sidebar="brand"].sm .vertical-menu #sidebar-menu > ul > li:hover > a {
  background-color: #0a0aff;
  color: white;
}
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  i,
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a
  svg {
  color: white;
}
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li.mm-active
  .active {
  color: white !important;
}
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li
  a:hover {
  color: #84091d;
}
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li.mm-active {
  color: #84091d !important;
}
body[data-sidebar="brand"].sm
  .vertical-menu
  #sidebar-menu
  ul
  li
  ul.sub-menu
  li.mm-active
  > a {
  color: #84091d !important;
}

body[data-layout-mode="dark"][data-sidebar="dark"] .vertical-menu,
body[data-layout-mode="dark"][data-sidebar="dark"] .navbar-brand-box,
body[data-layout-mode="dark"][data-sidebar="brand"] .vertical-menu,
body[data-layout-mode="dark"][data-sidebar="brand"] .navbar-brand-box {
  border-color: #373c39;
}
body[data-layout-mode="dark"][data-sidebar="light"] .sidebar-alert {
  background-color: rgba(0, 0, 255, 0.1);
  color: primary;
}
body[data-layout-mode="dark"][data-sidebar="light"]
  .sidebar-alert
  .alertcard-title {
  color: #84091d;
}

[dir="rtl"] #sidebar-menu .has-arrow:after {
  content: "\f0141";
}

.vertical-menu #sidebar-menu * {
  font-family: "IBM Plex Sans" !important;
  font-size: 0.85rem;
}
.detail_View {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: #84091d;
  padding-bottom: 12px;
}
#sidebar-menu .metismenu.list-unstyled.mm-show.mm-active .mm-active a.active {
  border-left: 5px solid #84091d;
}

#sidebar-menu .metismenu.list-unstyled.mm-show.mm-active .mm-active a.active {
  border-left: 5px solid #84091d;
}

/* ////Topbar */
#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #ffffff;
  border-bottom: 1px solid #e9e9ef;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 60px;
  padding: 0 20px 0 0;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  width: 250px;
  background: #fbfaff;
  border-right: 1px solid #e9e9ef;
  box-shadow: 0px 2px 2px #fbfaff;
}

.logo {
  line-height: 69px;
  color: inherit !important;
}

.logo .logo-sm {
  display: none;
}

.logo-light {
  display: none;
}

.logo {
  line-height: 61px;
  color: inherit !important;
}

.logo .logo-sm {
  display: none;
}

.sm #vertical-menu-btn {
  margin-left: 0;
  margin-right: 0;
}
.full {
  height: 100vh;
  overflow: hidden;
}

.full #vertical-menu-btn {
  margin-left: -52px;
  margin-right: -52px;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.header-item {
  height: 60px;
  box-shadow: none !important;
  color: #555b6d;
  border: 0;
  border-radius: 0px;
}

bg-soft-light {
  background-color: rgba(233, 233, 239, 0.25) !important;
}
.border-start {
  border-left: var(--bs-border-width) #e9e9ef var(--bs-border-color) !important;
}
.border-end {
  border-right: var(--bs-border-width) #e9e9ef var(--bs-border-color) !important;
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: #74788d;
  padding: 3px;
}
.rounded-circle {
  border-radius: 50% !important;
}

.bg-soft-light {
  background-color: rgba(233, 233, 239, 0.25) !important;
}

/* 
////Dropdown User Header  */

.dropdown-menu {
  box-shadow: 0 0.25rem 0.75rem rgba(18, 38, 63, 0.08);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  padding: 0.25rem;
  top: 3.8rem !important;
  min-width: 8rem;
  font-family: sans-serif;
  right: 0rem;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: #e9e9ef;
}

/* // Dropdown Mega Menu */

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}
/* 
// Dropdown size */

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #343a40;
  text-decoration: none;
  background-color: #f8f9fa;
}

/* ////Basic Layout of App */

.LayoutDiv {
  width: 98vw;
  height: calc(100vh - 60px);
  display: flex;
  margin-top: 60px;
}
.main-Content {
  padding: 19px 10px;
  padding-right: 0;
}

.TableHeaderFixed button {
  font-weight: 500;
}
.table-responsive {
  border: none !important;
  max-height: calc(100vh - 18rem) !important;
  overflow: auto !important;
  max-width: calc(100vw - 20.3rem);
}
table {
  caption-side: bottom;
}
.table > thead {
  vertical-align: bottom;
}
.table th:first-child,
.table td:first-child {
  width: 50px !important;
}

.table-nowrap th {
  position: sticky;
  top: -1px;
  background-color: white;
}
.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dee2e6;
}
.table th {
  font-weight: 700;
  background-color: white !important;
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: #00000000;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px #00000000;
}
.table > tbody {
  vertical-align: inherit;
}
.form-select {
  display: block;
  width: 100%;
  /* max-width: 12vw;  */
  /* padding: 0.47rem 1.75rem 0.47rem 0.75rem; */
  /* -moz-padding-start: calc(0.75rem - 3px); */
  font-size: 0.765rem;
  padding: 5px;
  margin: 5px 0;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  /* color: #495057; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  /* border: 1px solid #ced4da; */
  /* border-radius: 0.25rem; */
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  /* appearance: none; */
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

.table-empty {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 55vh;
}
.page-title-box {
  padding-bottom: 20px;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #84091d !important;
  color: #fff;
}

.swal2-icon.swal2-warning {
  border-color: #84091d !important;
  color: #84091d !important;
  font-size: 7px;
}
.swal2-icon.swal2-success {
  font-size: 7px;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #930303 !important;
  font-size: 5px;
}

.swal2-icon.swal2-error {
  /* border-color: #84091d; */
  color: #84091d;
  max-width: 24px;
  max-height: 27px;
  border: 1px solid #84091d;
}

/* //Login */

@media (min-width: 768px) {
  .auth-bg {
    height: 100vh;
  }
}
.auth-bg {
  background-image: url("../images/LoginBg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.auth-bg .bg-overlay {
  opacity: 0.5;
}

.bg-primary {
  background-color: #84091d !important;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 0.7;
  background-color: #000;
}

.auth-full-page-content {
  min-height: 100vh;
}
.auth-logo .logo-txt {
  color: #fff;
  font-size: 20px;
}
.auth-pass-inputgroup input[type="input"] + .btn .mdi-eye-outline:before {
  content: "\F06D1";
}
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bg-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  bottom: -50px;
  animation: square 20s infinite;
  transition-timing-function: linear;
}
.bg-bubbles li:nth-child(1) {
  left: 10%;
}
.bg-bubbles li:nth-child(2) {
  left: 20%;
  width: 120px;
  height: 120px;
  animation-delay: 2s;
  animation-duration: 17s;
}
.bg-bubbles li:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
}
.bg-bubbles li:nth-child(4) {
  left: 40%;
  width: 80px;
  height: 80px;
  animation-duration: 22s;
}
.bg-bubbles li:nth-child(5) {
  left: 70%;
  width: 90px;
  height: 90px;
}
.bg-bubbles li:nth-child(6) {
  left: 70%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
}
.bg-bubbles li:nth-child(7) {
  left: 32%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}
.bg-bubbles li:nth-child(8) {
  left: 55%;
  width: 80px;
  height: 80px;
  animation-delay: 15s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(9) {
  left: 25%;
  width: 50px;
  height: 50px;
  animation-delay: 2s;
  animation-duration: 40s;
}
.bg-bubbles li:nth-child(10) {
  left: 90%;
  width: 140px;
  height: 140px;
  animation-delay: 11s;
}
@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px) rotate(600deg);
  }
}

.btn-primary {
  box-shadow: 0 2px 6px 0 rgba(132, 9, 29, 0.5);
}
.btn-primary {
  color: #fff;
  background-color: #84091d;
  border-color: #84091d;
}
.btn-primary:active {
  color: #fff;
  background-color: #84091d;
  border-color: #84091d;
}
.btn-primary:hover {
  color: #fff;
  background-color: #6a0717;
  border-color: #630716;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-primary:focus {
  box-shadow: 1px 0px 8px 0px rgb(150, 46, 63);
  background-color: #6a0717;
  border-color: #630716;
}
.btn-primary:disabled {
  color: #fff;
  background-color: #84091d;
  border-color: #84091d;
}
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 6px 0 rgba(132, 9, 29, 0.5);
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(132, 9, 29, 1) !important;
}

.fw-semibold {
  font-weight: 600 !important;
}
label {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}
a {
  color: #84091d;
  text-decoration: none;
}

.page-content {
  padding: calc(5rem + 15px) calc(220px / 2) 60px calc(22rem / 2);
}
a:hover {
  color: var(--bs-link-hover-color);
  text-decoration: underline;
}

.border-bottomHome {
  border-bottom: 1px solid #000000 !important;
}
.has-arrow {
  cursor: pointer;
}
.protected_page_content .page-content {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 3.3rem;
  font-size: 0.85rem;
  color: #545a6d;
}

.metismenu li {
  display: block;
  width: 100%;
}
.modal-right {
  min-height: 100%;
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.load {
  filter: blur(4px);
}

.load img {
  position: relative;
  /* background-color: #CBD5DC; */
  width: 100%;
  height: calc(100vh - 16rem);

  /* border-radius: 13px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);  */
}
/* .load::after {
    display: block;
    content: ;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(#fff, 0.35),
      transparent
    );
    animation: loading 2.5s infinite;
  } */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.loading-logo {
  animation: 2s opacity infinite;
}

@keyframes opacity {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.loading-wrapper-global {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 95vh;
}

.h-100 {
  height: 100% !important;
}
.modal-right .modal-content {
  min-height: 100%;
  border: 0;
  border-radius: 0;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #84091d !important;
}
.preview-thumbsnav {
  display: flex !important;
  list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #84091d !important;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #84091d !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #84091d !important;
}

/* // Flatepicker */
.flatpickr-months,
.flatpickr-weekdays,
.flatpickr-monthDropdown-months {
  background-color: #42a5f5 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background-color: #42a5f5 !important;
}

.search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 13px;
  top: 0;
  line-height: 38px !important;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.cardPerTitle {
  color: #373b40;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.01px;
}
.cardPer {
  color: #696d6e;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.reportsTitle {
  color: #9f2539;
  border-bottom: 3px solid #9f2539;
  padding: 10px;
  padding-left: 0px;
}
.reportcardDesginCustom {
  overflow: hidden auto;
  max-height: calc(100vh - 18rem);
  min-height: calc(97vh - 16.5rem);
}
.tabModalScreen {
  position: absolute;
  height: 68vh;
  width: 80%;
  background: white;
  z-index: 1;
}
.goalsProgressBar {
  font-size: 11px;
  color: #9f2539;
}
.cardPerLine {
  color: #9f2539;
  font-family: "Roboto";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}
div:where(.swal2-icon) .swal2-icon-content {
  font-size: 1.75em !important;
}
.swal2-icon.swal2-warning {
  height: 35px !important;
  width: 35px !important;
}

/* .swal2-x-mark {
  font-size: 10px !important;
}
div:where(.swal2-icon) {
  width: 3em !important;
  height: 3em !important;
} */
.btnLastWeek {
  background-color: white !important;
  color: #84091d !important;
}
.limitUtilized {
  color: #84091d;
}
.tabContentPerformance {
  height: 100vh;
  overflow: auto;
}
.cardNum {
  color: #373b40;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
  padding-top: 5px;
  padding-bottom: 5px;
}
.complainChart {
  box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.1);
  border-radius: 16px;
  width: 709px;
}
.complaintChart {
  box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.1);
  border-radius: 16px;
}
.cardPerformance {
  width: 175px;
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 23px;
  border-radius: 16px;

  box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.1);
}
.distributorDetails {
  color: rgba(0, 0, 0, 0.4);
}
.distributorDetails tr {
  border-style: hidden;
}
.navbarForPerformance {
  border: 1px solid #e9e9e9 !important;
  padding: 20px;
}
.navbarForPerformance div {
  padding-right: 10px;
  margin-right: 25px;
  cursor: pointer;
}
.btnperformance {
  display: flex;
  width: 100px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  color: #9f2539;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #9f2539;
  background: none;
}
.performanceDetailHeight {
  height: calc(100vh - 12rem);
  overflow: auto;
  padding-left: 5px;
}
.detailProfile {
  /* margin-left: -71px; */
  box-shadow: 0px 1px 8px 0px rgba(110, 110, 110, 0.1);
  padding: 14px;
}
.profilePicPerf {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: -10px;
  margin-top: 8px;
}

.GlobalApprovalsTabsPer .nav-link {
  color: #545a6d;
}
.popupModule .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #84091d;
  background-color: #fff;
  font-weight: 700;
  border-color: #dee2e6 #dee2e6 #fff;
}
.popupModule.popupmoduleChange .nav-tabs .nav-item.show .nav-link,
.nav-tabs.nav-tabs-change .nav-link.active {
  color: #84091d;
  background-color: #fff;
  font-weight: 700;
  border-bottom: 1px solid;
  /* border-color: #dee2e6 #dee2e6 #fff; */
}
.popupModule .nav-tabs .nav-link {
  color: black;
}
.popupModule.popupmoduleChange .nav-tabs .nav-link {
  color: #9f2539;
}
.linkCustom {
  /* text-decoration: underline; */
  /* text-decoration: underline; */
  cursor: pointer;
}
.carousel-indicators button,
.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
  width: 30px !important;
  height: 3px !important;
}
.dashboard-slider.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}

.dashboard-slider.carousel-indicators {
  position: relative;
}
#reviewcarouselIndicators .carousel-indicators {
  margin-left: 0;
  float: left;
}
.bx {
  font-weight: 400;
}
.sweet-alert h2 {
  font-size: 22px !important;
  font-weight: 500 !important;
}
.dropdown-toggle::after,
.dropstart .dropdown-toggle::before {
  display: none !important;
}
/* //react-table */
/* .custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 20px;
        }
      }
    }
    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
} */
.carousel-indicators [data-bs-target] {
  font-size: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.targetAssignmentmainheaderCustom input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: initial;
  margin: 0;
}
/* Firefox */
.targetAssignmentmainheaderCustom input[type="number"] {
  -moz-appearance: initial;
}

.input-containercontainertargetAssignmentmainheaderCustom {
  position: relative;
  width: 200px; /* Set the width of the input container */
}

.input-containercontainertargetAssignmentmainheaderCustom input {
  width: 100%;
  border: 0;
  padding: 5x; /* Add padding to the input field for spacing */
}

.input-containercontainertargetAssignmentmainheaderCustom .placeholder {
  position: absolute;
  right: 7%;
  top: 36%;

  transform: translateY(-50%);
  color: #999; /* Placeholder text color */
}

.input-containercontainertargetAssignmentmainheaderCustom
  input:focus
  + .placeholder {
  display: none; /* Move the placeholder to the right when the input is focused */
}
.TableHeaderFixed {
  position: fixed;
  width: calc(100% - 287px);
  background-color: white;
  top: 61px;
  height: 60px;
  padding-top: 15px;
  z-index: 800;
}
.flow_dataParent .TableHeaderFixed + .row {
  margin-top: 0rem;
  width: 100%;
}
.whiteSpacePre {
  white-space: pre;
}

/* .tableofTargetAssignement tbody td{
border-right: 0;
border-left: 0;
margin-top: 4px;
} */
.targetAssignmentmainheaderCustom input[type="number"] {
  border: 0;
}
.tableofTargetAssignement td:focus {
  padding: 0;
}
.targetAssignmentmainheaderCustom input[type="number"]:focus {
  border: 1px solid black;
}
.flow_dataParent .TableHeaderFixed {
  position: relative;
  width: calc(100%);
  background-color: white;
  top: 0px;
  height: 44px;
  padding-top: 0;
}
.underline {
  text-decoration: underline;
  text-decoration-color: #84091d;
}
.TradeAddNewTabFormDiv {
  max-height: calc(100vh - 14rem);

  min-height: calc(100vh - 14rem);
  overflow: auto;
}
.TableHeaderFixed + .row {
  margin-top: 60px;
}

.scrollable-container {
  overflow-x: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #84091d transparent; /* For Firefox */

  /* For WebKit-based browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #84091d; /* Color of the scrollbar thumb */
  }
}

.scrollable-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: red transparent;
  position: relative;
}

.scroll-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: red; /* Button color */
}

.left-scroll {
  position: absolute;
  left: 0;
}

.right-scroll {
  position: absolute;
  right: 0;
}
.GlobalApprovalsTabs .nav-link {
  color: #000;
}
.GlobalApprovalsTabs .nav-link.active {
  color: #ffffff;
  background-color: #84091d;
}
[data-sidebar="dark"] .TableHeaderFixed {
  position: fixed;
  width: calc(100% - 280px);
  background-color: #313533;
  top: 61px;

  height: 134px;
  padding-top: 15px;
}
.elevenRem#workflow_table {
  max-height: calc(98vh - 10rem) !important;
  min-height: calc(98vh - 10rem) !important;
}
.bodercustompop {
  border: 1px solid black;
  box-shadow: 10px 10px 10px;
}
.splitCardHeight .split_card_button {
  cursor: default;
}
.cardDesginCustom .split_card_button {
  cursor: default;
}
.journeyLogsCard .split_card_button {
  cursor: default;
}
/* //capitalize initial text of input fields */
/* input[type="text"] {
  text-transform: capitalize;
} */
.font-size-1rem {
  font-size: 1rem;
}
.save-user {
  padding-left: 24px;
  padding-right: 24px;
}
.table-responsive {
  border: none !important;
  max-height: calc(100vh - 18rem) !important;
  overflow: auto !important;
  max-width: calc(100vw - 20.3rem);
}

.sales_group_table .table-responsive {
  max-height: calc(100vh - 45vh) !important;
}
.customize-label {
  font-size: 14px; /* Adjust the font size as needed */
  font-weight: 800;
}
#sidebar-menu {
  font-family: "IBM Plex Sans", sans-serif !important;
}
#sidebar-menu .metismenu.list-unstyled.mm-show.mm-active .mm-active a.active {
  border-left: 5px solid #84091d;
}

.StepperModuleStep li {
  cursor: pointer;
}

.StepperModuleStep li.active svg {
  color: darkred;
}

.StepperModuleStep li.active h4 {
  color: darkred;
}
.mainStepperInportExport label {
  margin-bottom: 0 !important;
}
.BulkUpdate .table-responsive {
  height: auto;
  max-height: -webkit-fill-available !important;
}
.company_structure .table_wrap {
  border: 0;
}
.company_dataParent.company_structure {
  max-height: 88vh;
  overflow: auto;
}
.sm .TableHeaderFixed {
  width: calc(100% - 8rem);
}
.cursorPointer {
  cursor: pointer;
}
.toogleStyle input,
.toogleStyle label {
  cursor: pointer;
}
.toogleStyle input {
  display: flex;
  appearance: none;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2) inset,
    0 1px 1px 1px rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  background: linear-gradient(
      to right,
      #900 25%,
      transparent 25%,
      transparent 75%,
      #090 75%,
      #090 100%
    ),
    linear-gradient(to bottom, #555, #876);
  background-size: 200% 100%, 100% 100%;
  background-blend-mode: soft-light;
}
.toogleStyle input::before,
.toogleStyle input::after {
  display: flex;
  position: relative;
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0.5rem;
}
.toogleStyle input::before {
  content: "Active";
}
.toogleStyle input::after {
  content: "Non Active";
}
.toogleStyle input:not(:checked) {
  background-position: 0 0;
  transition: all 250ms ease-out;
}
.toogleStyle input:not(:checked)::before {
  color: #fc9;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:not(:checked)::after {
  color: rgba(255, 255, 255, 0.4);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:checked {
  background-position: 100% 0;
  transition: all 150ms ease-in;
}
.toogleStyle input:checked::before {
  color: rgba(255, 255, 255, 0.4);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:checked::after {
  color: #9fc;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
  transition: all 75ms ease-in 100ms;
}
.bulkupdate1ststep1strow {
  border: 1px solid #e9e9e9 !important;
  /* border-top: 0; */
  margin-top: 0;
}

.modal-right75 {
  max-width: 100% !important;
  min-height: 100%;
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.StepperModuleStep li {
  cursor: pointer;
}
.StepperModuleStep li svg {
  width: 13px !important;
}
/* .modal-title {
  font-size: 20px !important;
} */
.StepperModuleStep .font-size-16 {
  font-size: 13px;
}
@media (min-width: 576px) {
  .modal-dialog:has(form) {
    max-width: 18rem !important;
    margin: 1.75rem auto;
  }

  .modal-dialog.modal-right75 {
    max-width: 36rem !important;
    margin: 1.75rem auto;
  }
}
.StepperModuleStep li.active svg {
  color: darkred;
}

.StepperModuleStep li.active h4 {
  color: darkred;
}

.BulkUpdate .table-responsive {
  height: auto;
  max-height: -webkit-fill-available !important;
}

.toogleStyle input,
.toogleStyle label {
  cursor: pointer;
}
.toogleStyle input {
  display: flex;
  appearance: none;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2) inset,
    0 1px 1px 1px rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  background: linear-gradient(
      to right,
      #900 25%,
      transparent 25%,
      transparent 75%,
      #090 75%,
      #090 100%
    ),
    linear-gradient(to bottom, #555, #876);
  background-size: 200% 100%, 100% 100%;
  background-blend-mode: soft-light;
}
.toogleStyle input::before,
.toogleStyle input::after {
  display: flex;
  position: relative;
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0.5rem;
}
.toogleStyle input::before {
  content: "Active";
}
.toogleStyle input::after {
  content: "Non Active";
}
.toogleStyle input:not(:checked) {
  background-position: 0 0;
  transition: all 250ms ease-out;
}
.toogleStyle input:not(:checked)::before {
  color: #fc9;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:not(:checked)::after {
  color: rgba(255, 255, 255, 0.4);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:checked {
  background-position: 100% 0;
  transition: all 150ms ease-in;
}
.toogleStyle input:checked::before {
  color: rgba(255, 255, 255, 0.4);
  transition: all 75ms ease-in 100ms;
}
.toogleStyle input:checked::after {
  color: #9fc;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.25);
  transition: all 75ms ease-in 100ms;
}
.bulkupdate1ststep1strow {
  border: 1px solid #e9e9e9;
  border-top: 0;
  margin-top: 0;
}
.bulkupdateSelectedShadow {
  box-shadow: -4px 4px 14px 6px #e9e9e9;
  margin: 0;
  margin: 0 !important;
  padding: 0.8rem;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.swal2-popup {
  width: 23em !important;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto; /* Scroll on this element otherwise element cant have a padding applied properly */
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  padding: 0px !important;
}
.headerProfileText {
  border-radius: 2rem;
  padding: 6px;
  color: white;
  width: 30px;
  height: 30px;
  font-weight: bolder;
  font-size: 12px;
}
.gisFilters {
  position: absolute;
  z-index: 999;
  right: 3rem;
  bottom: 1rem;
}

.swal2-html-container {
  font-size: 0.825em !important;
}
.swal2-title {
  font-size: 0.875em !important;
}
/* .swal2-actions{
  margin: 0.25em auto 0 !important;
} */
/* .swal2-actions{
  margin: 0 !important;
}
.swal2-icon{
  width: 2em !important;
    height: 2em !important;
}
.swal2-icon .swal2-icon-content{
  font-size: 1.75em !important;
}
.swal2-title{
  font-size: 1.2em !important;

}
.swal2-html-container{
  margin: 0 !important;
}
.swal2-styled.swal2-confirm{
  font-size: 0.9em !important;
}
.swal2-styled.swal2-cancel{
  font-size: 0.9em !important;
} */
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.icon_flow img[style*="height: 50px;"] {
  /* Your CSS rules for images with height: 50px in the style attribute */
  height: 50px !important;
}
.simplebar-content:before,
.simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}

.custom-scroll {
  height: 100%;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  color: grey;
  font-weight: 500;
}
.nav-pills > a {
  color: grey;
  font-weight: 500;
}
.nav-tabs-custom {
  border-bottom: 1px solid gray;
}
.nav-tabs-custom .nav-item {
  position: relative;
  color: gray-dark;
}
.nav-tabs-custom .nav-item .nav-link {
  border: none;
}
.nav-tabs-custom .nav-item .nav-link::after {
  content: "";
  background: #84091d;
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}
.nav-tabs-custom .nav-item .nav-link.active {
  color: #84091d;
}
.nav-tabs-custom .nav-item .nav-link.active:after {
  transform: scale(1);
}
.nav-tabs-custom.card-header-tabs {
  border-bottom: none;
}
.nav-tabs-custom.card-header-tabs .nav-link {
  padding: 1.25rem 1rem;
  font-weight: 500;
}
.nav-item .nav-link.active {
  color: #84091d;
}
.nav-item .nav-link.active svg {
  color: #84091d;
  fill: rgba(132, 9, 29, 0.2);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #84091d;
  width: 45px;
  height: 40px;
  border-radius: 50%;
}
.bulk_icons {
  font-size: 15px;
}
.bulk_icons2 {
  font-size: 17px;
}

.navbarSelectionOfMainRoutesIconCustom {
  font-size: 24px;
  color: #84091d;
}

/* ////////////////////////////// */
/* Skeleton loading CSS class */
.skeleton-loading {
  width: 100%;
  height: 16px; /* Adjust the height as needed */
  background-color: #f0f0f0; /* Set the background color */
  border-radius: 4px; /* Add border-radius for rounded corners */
  animation: skeleton-loading-animation 1s infinite ease-in-out; /* Apply animation */
}

/* Animation keyframes for skeleton loading */
@keyframes skeleton-loading-animation {
  0% {
    opacity: 0.6; /* Starting opacity */
  }
  50% {
    opacity: 0.8; /* Mid opacity */
  }
  100% {
    opacity: 0.6; /* Ending opacity */
  }
}
Input::placeholder {
  font-size: 0.879rem;
  color: hsl(0, 0%, 50%);
}
textArea::placeholder {
  font-size: 0.879rem;
  color: hsl(0, 0%, 50%);
}
select::placeholder {
  font-size: 0.879rem;
  color: hsl(0, 0%, 50%);
}
.fontSizeCards {
  margin-bottom: 0px;
  font-size: 12px;
}
.fontSizeCardsText {
  font-size: 10px;
}
.flow_dataParent .flow_data {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}
.flow_dataParent .main_flow {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  border-bottom: 2px solid #ced4da;
  flex: 1;
  align-items: center;
}
.flow_dataParent .check_flow {
  margin: 0 30px 0 0;
}

.flow_dataParent .flow-modal-form {
  margin: 0 auto;
  padding: 1em;
  height: auto;
}
.flow_dataParent .modal-title {
  color: #9f2539;
  /* border-bottom: 2.5px solid #9f2539; */
  padding-bottom: 12px;
  font-size: 14px;
}

.flow_dataParent .ul.list_data {
  list-style: none;
  padding: 0;
  margin: 0;
}

.flow_dataParent .form li.modal_list {
  margin-top: 1em;
}
.flow_dataParent .modal_list label {
  display: flex;
}
.flow_dataParent .modal-input {
  font: 1em sans-serif;
  width: 100px;
  box-sizing: border-box;
  border: 1px solid #999;
  border: 0;
  border-bottom: 1px solid gray;
}

.flow_dataParent .input:focus:focus {
  border-color: #000;
}
.map-container {
  width: 100%;
  height: 50vh;
}

.flow_dataParent .flow-permission {
  font-size: 14px;
  margin-bottom: 0px;
  font-size: 12px;
}
.flow_dataParent .auth-tree {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10px;
}
.flow_dataParent .auth-tree-text {
  font-size: 10px;
}

.flow_dataParent .select,
textarea {
  font: 1em sans-serif;
  width: 200px;
  box-sizing: border-box;
  border: 1px solid #999;
  border: 0;
  border-bottom: 1px solid gray;
}

.flow_dataParent .select:focus,
textarea:focus {
  border-color: #000;
}

.flow_dataParent .main_wrap {
  display: flex;
  margin-left: 30px;
  margin-top: 40px;
}

.flow_dataParent .view_flow {
  cursor: pointer;
}
.view_flow.changingView {
  margin-right: 0.5rem;
}
.flow_dataParent.row-info {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.row-info {
  margin-left: 10px;
}
.flow_dataParent .list_label {
  display: flex;
  margin-top: 20px;
}
.flow_dataParent .form-list {
  margin-top: "30px";
  list-style: none;
}

.flow_dataParent.title {
  display: flex;
  margin-left: 40px;
  color: rgb(159, 37, 57);
  border-bottom: 3px solid;
  padding: 10px;
}

.d-flex.justify-content-between > li {
  list-style: none;
}

.containerStep3 {
  display: flex;
  justify-content: center;
  min-height: 120px;
}

.file-uploader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-upload-select {
  padding: 10px 20px;
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-upload-select:hover {
  background-color: #1a73e8;
}

.file-select-name {
  font-size: 14px;
}
.AssignOutlet {
  max-width: 76vw;
  margin-left: 31px;
}
.AssignOutlet .w-35 {
  min-width: 35%;
}
.AssignOutlet .w-20 {
  min-width: 20%;
}
.AssignOutlet .w-45 {
  min-width: 45%;
}

.DragandDropStep3ImportExportWidgetParent #form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.DragandDropStep3ImportExportWidgetParent #input-file-upload {
  display: none;
}

.DragandDropStep3ImportExportWidgetParent #label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.DragandDropStep3ImportExportWidgetParent #label-file-upload.drag-active {
  background-color: #ffffff;
}

.DragandDropStep3ImportExportWidgetParent .upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.DragandDropStep3ImportExportWidgetParent .upload-button:hover {
  text-decoration-line: underline;
}

.DragandDropStep3ImportExportWidgetParent #drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
/* Company Information css */
.company_dataParent .company_title {
  margin-bottom: 0;
  margin-top: 7px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-left: 5px;
  color: #84091d;
}
.company_dataParent .lucky_data {
  display: flex;
  border-bottom: 2px solid #e9e9ef;
  box-sizing: border-box;
  background: #ffffff;
  /* border: 1px solid #e9e9ef; */
  border-radius: 4px;
  padding: 10px 20px;
  align-items: center;
  margin-left: 10px;
}

.company_dataParent .company_tag {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  margin-left: 10px;
  /* identical to box height */
  color: #74788d;
}

.company_dataParent .info_tabs {
  display: flex;
  list-style: none;
}
.company_dataParent .items_list {
  margin: 0 10px;
}
.company_dataParent .Nav_info {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #495057;
}
.company_dataParent .company-wrap {
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
}
.company_dataParent .companny_table {
  margin: 20px;
}

/* .companny_table {
  background: #ffffff;
  border: 0.5px solid #e9e9ef;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 17px;
} */
.company_dataParent .currencies_label {
  background: #ffffff;
  border: 0.5px solid #e9e9ef;
  border-radius: 4px;
}
.company_dataParent .name_wrap {
  background: #ffffff;
  border: 0.5px solid #e9e9ef;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.company_dataParent .info-company {
  border-top: 0;
  border-bottom: 0px 0px 1px 0px solid#f1f1f1; /* 25px top, 10px right, 4px bottom and 35px left */
  border-left: 0;
  border-right: 0;
  margin: 10px;
  width: 500px;
  padding: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e6e6e;
  opacity: 0.6;
}

.company_dataParent .info_list {
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.company_dataParent .company_name {
  margin-left: 10px;
  margin-left: 10px;
  color: #212121;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.financialYearModalMapStyleinForm {
  border: 1px solid;
  margin-left: 0;
  margin-top: 5px;
  border-radius: 3px;
  border-color: #80808054;
  box-shadow: -2px 0px 2px 0px;
}
.company_dataParent .table_wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 0px solid #e9e9ef;
  border-radius: 4px;
  margin-top: 0px;
}
.btn-outline-primary {
  color: #84091d;
  border-color: #84091d;
}
.plus_icon_order {
  color: #9f2539;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-bottom: 2px red;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #84091d;
  border-color: #84091d;
}
.company_dataParent .profile_info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #495057;
}

.company_dataParent .company_detail_data {
  margin: 0 20px !important;
}

.company_dataParent .company_structure {
  margin: 20px !important;
}
.fieldDropdown.show {
  position: absolute;
  inset: 0px auto auto 6rem;
  margin: 0px;
  transform: translate(70px, 40px);
  width: 40px;
  left: 6rem;
  top: 2.2rem !important;
}
.fiscal_data {
  display: flex;
  justify-content: space-between;
}
.cursor-pointer {
  cursor: pointer !important;
}
.fiscal-data-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #9f2539;
}
.parent_company.company_btn_adress {
  background-color: "#9F2539";
  border: "none";
  color: "white";
  padding: "4px 7px";
  text-align: "center";
  border-radius: "8px";
  text-decoration: "none";
  display: "inline-block";
  font-size: "14px";
  margin-bottom: "10px";
}
.userProfilePic {
  border-radius: 50%;
  height: 70px;
  width: 70px;
}
.profilePageHeight {
  max-height: 87vh;
  overflow: auto;
  padding-bottom: 78px !important;
}
.imgButtonProfilePic {
  border: none;
  background: none;
}

/* textarea style */
.textarea_body_textarea {
  width: 100%;
  height: 150px;
  /* padding: 12px 20px; */
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  color: #212529;
  background-color: #802c2c;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
  padding: 10px;
  /* text-indent: 20px; */
}
ul li #highlights:hover {
  color: #84091d;
  /* w */
}
.progressBartLabel {
  text-align: center;
  color: #84091d;
}
/* Workflow css start*/
.react-datepicker-popper {
  z-index: 5 !important;
}

.workflow_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0% 2%;
}
.workflow_wrapper #workflow_table {
  flex-basis: 70%;
  /* max-height: calc(98vh - 5rem); */
  min-height: calc(98vh - 6rem);
  overflow-y: auto;
  overflow-x: hidden;
}
.workflow_wrapper #workflow_form {
  flex-basis: 25%;
  min-height: 80.5vh;
  overflow: auto;
  max-height: 80vh;
  box-shadow: 0px 2px 10px 0 #00000014;
  position: relative;
  /* top: 30px; */
  /* position: fixed;
  right: 50px;
  width: 20%; */
}
.workflow_list_item {
  list-style-type: none;
  width: 100%;
}
.workflow_input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  height: 40px;
  margin-bottom: 15px;
  padding: 10px;
  font-weight: 900;
}
.workflow_list_data {
  padding-left: 7px;
}
.plus_icon_flow {
  width: 22px;
  height: 24px;
  padding: 3px;
  border-radius: 1px;
  border: 0.75px solid #9f2539;
}
.minus_icon_flow {
  width: 20px;
  height: 20px;
  padding: 0.5px;
  border-radius: 1px;
  border: 0.75px solid #9f2539;
}
.split_card_title {
  color: #373b40;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.split_card_text {
  color: #373b40;
  font-family: IBM Plex Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
.split_card_button {
  display: inline-flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #919191;
  flex-shrink: 0;
  background: none;
  width: auto;
}
.splitCardHeight {
  max-height: calc(100vh - 17rem);
  min-height: calc(100vh - 17rem);
  overflow: auto;
}
.split_card_buttontwo {
  color: #9f2539;
  border-radius: 4px;
  border: 1px solid #9f2539;
}
.select-container select option:first-child {
  /* Your styles for the first <option> element here */
  display: none;
}
.table-empty img {
  width: 21%;
}
.cardforaction {
  position: absolute;
  width: 118px;
  padding-left: 12px;
  padding-top: 10px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding-right: 17px;
  margin-bottom: 200px;
}
.cardforaction .list-unstyled li:hover {
  color: #9f2539;
}
.splitCard {
  border-bottom: 2px solid #ced4da;
}
.sm .hrsm {
  width: 841px;
}
.tradaAgreementNavbar {
  border-bottom: 1px solid #e9e9ef;
}
.tradaAgreementNav {
  color: #acacac;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 21px;
}
/* Workflow css end */
/* Tagged outlet css */
.details_tagged {
  color: #9f2539;
  padding: 10px;
  background-color: #f1eced;
  border-bottom: 1px solid #9f25391a;
  margin-top: 20px;
}
.outlet_other_info {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.map_info_data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview_image {
  height: 200px;
  border: 1px;
  background-color: #ced4da;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: cover;
}
.fontSize12 {
  font-size: 12px !important;
}
.fontSize10 {
  font-size: 10px !important;
}

.closeBtn {
  position: absolute;
  top: 6px;
  right: 9px;
}
.AssignScreenAssignDoChildMainTable1 {
  min-height: calc(100vh - 15rem);
  max-height: calc(100vh - 13rem);
  border-right: 1px solid;
  padding: 8px;
  overflow: auto;
}
.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}
.map-container {
  height: calc(100vh - 90px);
  width: calc(100vw - 300px);
  margin-left: 250px;
}
.w-60 {
  width: 60% !important;
}
.w-40 {
  width: 40% !important;
}
.popupModuleForSalesGroup {
  position: absolute;
  z-index: 1060;
  background: white;
  width: 82%;
  height: 84%;
  top: 10px;
}
.second.Lucky.load img {
  height: calc(100vh - 23rem);
}
.popupModuleForSalesGroup.addingMore {
  top: 105px !important;
  width: 93% !important;
}
.popupModule {
  position: absolute;
  z-index: 1060;
  background: white;
  width: 81%;
  height: 81%;
  box-shadow: 0px 1px 3px #00000042;
}
.popupModule.popupmoduleChange {
  position: absolute;
  top: 4rem;
  z-index: 1060;
  background: white;
  width: 82%;
  height: 92.5%;
  /* border: 2px solid red; */
  box-shadow: none;
  overflow: auto;
}
.popupModuleNestedLevel1 {
  position: absolute;
  z-index: 1070;
  background: white;
  overflow: auto;
  width: 82%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px;
}
.popupModuleNestedLevel1.addingMore {
  border: 2px solid rebeccapurple;
  left: 0rem !important;
  width: 100% !important;
  top: 0rem !important;
  height: 80% !important;
}
/* /* complain mgt css start */
.item_image_upload {
  /* width: 315px; */
  height: 250px;
  border: 1px dotted #6c757d;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

/* AssignedJourney css statrt */
.mask_item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
/* AssignedJourney css end */
.border-primary {
  border-color: rgba(132, 9, 29, 1) !important;
}
.splittedCards {
  margin: 13px;
}
.split_card_buttonProcess {
  display: flex;

  border-radius: 4px;
  border: 1px solid #dcab00;
  color: #dcab00;
}
.split_card_buttonPending {
  color: #919191;
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
}
.split_card_buttonReady {
  color: #603e95;
  border-radius: 4px;
  border: 1px solid #603e95;
}
.split_card_buttonDispatched {
  color: #4980d3;
  border-radius: 4px;
  border: 1px solid #4980d3;
}
.split_card_buttonCompleted {
  color: #36be2a;
  border-radius: 4px;
  border: 1px solid #36be2a;
}
.split_card_buttonCancel {
  color: #e71616;
  border-radius: 4px;
  border: 1px solid #e71616;
}
.split_card_buttonReject {
  color: #9f2539;
  border-radius: 4px;
  border: 1px solid #9f2539;
}
.splitLinkText {
  font-size: 13px;
}
.assignDoOrderMainImages {
  width: 56px;
  height: 56px;
  fill: #f0f0f0;
}

.AssignDoOderNoandSubHeadingMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0;
  margin-left: 0.5rem;
}
.AssignDoOderNoandSubHeadingMainChild1 {
  display: flex;
  height: 1.2rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  margin-top: 0.95rem;
}
.AssignDoOderNoandSubHeadingMainChild2 {
  display: flex;
  min-width: 256.183px;
  height: 14.609px;
  flex-direction: column;
  justify-content: center;
}
.orderscreenAssignDo {
  width: 22rem;
}
.View-CardContainer {
  min-height: calc(100vh - 12rem);
  max-height: calc(100vh - 14rem);
  /* border-right: 1px solid; */
  padding: 8px;
  overflow: auto;
}

.LayoutDiv:has(.AssignScreenAssignDo) {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  margin-top: 60px;
}
.AssignScreenAssignDoChildMainTable1heading {
  background-color: #efe9e9;
  padding: 10px;
}
.pendingorder .mb-3.g-2.row {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.react-datepicker__input-container .form-control {
  width: 253px;
}
/* start PendingOrder */
.detail_data {
  background-color: #f5e9eb;
  height: 39px;
  width: 690px;
  font-size: 12px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  border-bottom: 1px solid #9f2539;
}
.pending_order {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order_booking {
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #982626;
  border-bottom: 1px solid #9f2539;
}
/* status modal css */
.modal-open {
  display: flex;
}

.modal-closed {
  display: none;
}
.btn_display_data {
  /* width: 78px; */
  height: 30px;
  padding: 4px 16px 4px 16px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;

  min-height: 39px;
  opacity: 0;
  width: 132px;
}
.upload-btn-wrapper {
  position: relative;
  display: inline-block;
}
.w-98 {
  width: 98% !important;
}

/* market outlet priority start  */

.cardDesginCustom {
  overflow: hidden auto;
  max-height: calc(98vh - 18rem);
  min-height: calc(98vh - 16.5rem);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1003; /* Ensure the overlay is above other content */
}

/* CSS for the modal */
/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* CSS for the modal content */

.custom-outlet-modal {
  top: 186px;
  box-shadow: 4px 10px 10px 10px rgb(179 179 179 / 3%);
  font-size: 12px;
  font-weight: bold;
}

/* progressBar css */

/* Add this CSS to your project's stylesheet, e.g., App.css or ProgressBar.css */

/* Styling for the circles */
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: -5px;
  transform: translateX(-50%); /* Center the circle horizontally */
  cursor: pointer; /* Add pointer cursor to indicate interactivity */
}

.circle.red {
  background-color: red;
}

/* Styling for the lines */
.line {
  width: 2px;
  height: 15px;
  background-color: #333; /* Adjust the line color */
  position: absolute;
  top: 8px; /* Adjust the vertical position of the line */
  transform: translateX(-50%); /* Center the line horizontally */
}
.parent-container {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
}

h1 {
  margin-bottom: 20px;
}

/* Progressbarr css */

.container {
  position: relative;
  width: 100%;
  margin: 50px 0;
}

/* .button-container {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
} */

.circle-button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #9f2539;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}
.progress-container {
  position: relative;
  padding: 25px 5px;
}
.circle-button {
  position: absolute;
  top: 19px;
}
.circle-button span {
  margin-top: 60px;
}

.border-line {
  /* background-color: red; */
  background-color: #9f2539;
  height: 3px;
  width: 100%;
  position: relative;
}
.coloured-box {
  background-color: #9f2539;
}

/* Area voilt */
.issued_warning {
  color: #e71616;
  background-color: white;
  border: 1px solid #e71616;
  padding: 4px 10px;
  border-radius: 4px;
}
.modal-right .chrome-picker {
  width: 258px !important;
}
.issued_fine {
  color: #36be2a;
  background-color: white;
  border: 1px solid #36be2a;
  border-radius: 4px;
  padding: 4px 10px;
}
.issued_upcoming {
  color: #dcab00;
  background-color: white;
  border: 1px solid #dcab00;
  border-radius: 4px;
  padding: 4px 10px;
}
.issued_expired {
  color: #6e6e6e;
  background-color: white;
  border: 1px solid #6e6e6e;
  border-radius: 4px;
  padding: 4px 10px;
}
.popupModule .table-responsive {
  max-height: calc(100vh - 27rem) !important;
}
.popupModule .load {
  filter: blur(4px);
  max-height: calc(100vh - 27rem) !important;
  overflow: hidden;
}
.hoverIcon:hover {
  color: #9e656f !important;
}
.all-w-32 {
  width: 32%;
  min-width: 32%;
  max-width: 32%;
}
.firstRowLarge .table th:first-child,
.table td:first-child {
  width: 10rem !important;
}
.module_name {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #9f2539;
  margin-bottom: 20px;
  margin-top: 10px;
}
.navBarMenu {
  color: #9f2539;
  font-weight: bold;
}

/* warning and tick icon style */

.icon-warning {
  width: 4rem;
  height: 4rem;
  border: 1px solid #952727;
  /* background-color: #ffbebe; */
  border-radius: 50%;
  /* background-color: rgb(255, 190, 190); */
  display: flex;
  padding: 20px;
}
.btn_settings {
  background-color: #f1f1f1;
  color: #9f2539;
  padding: 10px 16px 10px 16px;
  width: 190px;
}
.warning_text {
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}
.btn-outline-DoAlailable {
  font-weight: bolder;
  color: #36be2a;
  border-color: #36be2a;
}
.btn-outline-Cancelled {
  font-weight: bolder;
  color: #e71616;
  border-color: #e71616;
}

.btn-outline-Consumed {
  font-weight: bolder;
  color: #ffa500;
  border-color: #ffa500;
}
.btn-outline-Assigned {
  font-weight: bolder;
  color: #00f;
  border-color: #00f;
}
.addimagescontainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.imageCrossButton {
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  color: crimson;
  font-size: 30px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}
.imageCrossButton:hover {
  background: crimson;
  color: white;
}
.areaVoilationImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid #c5c5c5;
  margin: 10px;
  width: 100%;
  height: 250px;
  position: relative;
}
.areaVoilationFileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid #c5c5c5;
  margin: 10px;
  width: 100%;
  height: 55px;
  position: relative;
}
.areaVoilationFileContainer:hover,
.areaVoilationImageContainer:hover {
  cursor: pointer;
}
.modalImageCloseIcon {
  background: #fdfdfd;
  color: crimson;
  font-size: 20px;
  border: none;
  position: absolute;
  top: 0;
  right: 10px;
}
.selectedImagePreviewModal .modal-content {
  background: transparent !important;
  border: 0 !important;
}

.imageuploadfileinput {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1px;
}

.imageuploadfileinput::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #fff;
  padding: 2px 20px;
  border-radius: 10px;
  color: black;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border: 1px solid #cccc;
}
.imageuploadfileinput::file-selector-button:hover {
  background: #84091d;
  color: white;
}
.my-async-select__control::before {
  content: "Type to Search";
  color: #999; /* Adjust the color as needed */
}

.main-Content:has(.AudianceTargetAssignment) {
  padding: 22px 41px;
  padding-right: 0;
  width: 53%;
}
.main-Content:has(.faizanAudiance) {
  padding: 10px;
  padding-right: 20px;
}

.AudianceTargetAssignment .table-responsive {
  max-height: calc(100vh - 26rem) !important;
}

.tableofTargetAssignement td {
  padding: 12px;
  border: 1px solid black;
}
.textWrap {
  word-wrap: break-word;
}
.swal-icon-size {
  font-size: 16px; /* Adjust the size as needed */
}
.systemlogs .TableHeaderFixed + .row {
  margin-top: 0px;
}
.systemlogs .table-responsive {
  max-height: calc(100vh - 15rem) !important;
}
/* Bulk Modal css */
.bulk_modal {
  font-size: 14px;
}

.bulk_data {
  font-size: 12px;
}
div:where(.swal2-icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em !important;
  height: 4em !important;
  margin: 2.5em auto 0.6em;
  /* border: 0.25em solid rgba(0,0,0,0); */
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  margin: 0.3125em;
  padding: 0.325em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  font-weight: 500;
}
.audienceEdit {
  position: absolute;
  width: 80%;
  height: 100vh;
  top: 8px;
  z-index: 3333;
}
div:where(.swal2-container) button:where(.swal2-styled) {
  margin: 0.3125em;
  padding: 0.325em 1.1em !important;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
  font-weight: 500;
}
.viewList_btn {
  margin-right: 10px;
}
.mainRebate {
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  border-radius: 5px;
}
.headingRebate {
  color: #9f2539;
  margin: 1rem;
  border-bottom: 1px solid #9f2539;
}

.inputRebate {
  display: flex;
  border: 1px solid #dee2e6;
  margin: 1.5rem;
  border-radius: 5px;
}
.rabateButton {
  margin-left: 94.3%;
  margin-bottom: 2rem;
}
.rebateTextArea {
  height: 152px;
  width: 100%;
}
.requiredLabel {
  color: #dc3545;
  font-weight: bolder;
}
.defineAudienceButton {
  margin-left: 90%;
}
.achiementFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9f2539;
}
.custom-deny-button-class {
  color: #9f2539 !important;
  border: 1px solid #9f2539 !important;
}
.flow-modal-form.onlyforRebate {
  width: 23rem;
  overflow: auto;
  max-height: calc(98vh - 17rem);
  min-height: calc(98vh - 7.5rem);
  box-shadow: 0px 2px 10px 0 #00000014;
}
.flexField {
  width: 50%;
  margin: 1rem;
}
.flexField.NotFlex {
  width: 100%;
}
.loadingSetting {
  display: none;
}
.loadingSetting1 {
  width: 25rem;
  height: 20rem;
}
.changeBreadCrumbs {
  padding-top: 0.7rem;
}
.streetAddress {
  border-radius: 0.25rem;
  height: 19%;
  border: 1px solid #ced4da;
  width: 87%;
}
.checkTODO {
  background-color: #3498db !important;
}
.streetAddress.MoreChanges {
  height: 36%;
}

.streetAddressTwo {
  border-radius: 0.25rem;
  height: 13%;
  border: 1px solid #ced4da;
  width: 100%;
}
.addFormHeight {
  height: calc(100vh - 12rem);
  overflow: auto;
  padding: 13px;
  padding-left: 0px;
}
.notes {
  border: 1px solid #ced4da;
  height: 170px;
  border-radius: 0.25rem;
}
.drop-area {
  /* margin-top: 8rem; */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 50px;
}
.dragButton {
  border: none;
  background: none;
  padding-bottom: 1rem;
  color: #84091d;
  padding-left: 0.5rem;
}
.fieldAdjust {
  padding-top: 3px;
}
.trancationUpload {
  margin-top: 8.5rem;
}
.headingChangingMore {
  margin-top: -2.5rem;
  border-bottom: 1px solid #9f2539;
  width: 30%;
}
.main_flow.changingMargin {
  margin-top: -10px !important;
}
.allOutletModelCenter {
  margin-top: 13rem;
}
.custom-table {
  border-collapse: collapse;
  width: 100%;
}
.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(5x);
    transform: translateX(5px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
}
.border-info {
  border-color: #cf4858 !important;
}

.btn-outline-info {
  color: #cf4858;
  border-color: #cf4858;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}
.dropdown-menu1 {
  position: absolute;
  top: 60%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-menu1 li {
  padding: 8px 12px;
  cursor: pointer;
}
.dropdown-menu1 li:hover {
  background-color: #f8f9fa;
}